



































































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { listMixin } from "@/mixins/listMixin";
import MainHeader from "@/components/main-header/main-header.vue";
import NavLeft from "@/components/nav-left/nav-left.vue";
import {
  addTopic,
  editTopic,
  getTopic,
  delTopic,
  getTemplate,
} from "@/request/topic";
import { GetCurrentUserData } from "@/request/account";
@Component({
  components: {
    MainHeader,
    NavLeft,
  },
})
export default class Name extends mixins(listMixin) {
  private data: any = [];
  private ifShowAdd: any = false;
  private editData: any = {};
  private file: any = {};
  private user: any = {};
  private ifCanEdit: any = false;
  private download() {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    getTemplate(this)
      .then((res: any) => {
        loading.close();
        window.open(res.template_path);
      })
      .catch(() => {
        loading.close();
      });
  }
  private openAdd() {
    this.file = {};
    this.editData = {};
    this.ifShowAdd = true;
  }
  private openEdit(item: any) {
    this.file = {};
    this.editData = JSON.parse(JSON.stringify(item));
    this.ifShowAdd = true;
  }
  private deleteFile() {
    this.file = {};
    this.editData.file = "";
  }
  private read(item: any) {
    window.open(item.file_url);
    return;
    const type: any = item.file_name.split(".")[1];
    if (
      type == "ppt" ||
      type == "pptx" ||
      type == "doc" ||
      type == "docx" ||
      type == "xls" ||
      type == "xlsx"
    ) {
      this.$router.push({
        path: "/main/topic/caijishuoming/read",
        query: {
          url: item.file_url,
        },
      });
    } else {
      window.open(item.file_url);
    }
  }
  /**
   * 新增课题确定
   */
  private addSure(ifUpload?: any) {
    if (!this.editData.name) {
      this.$message.warning("课题名称必填！");
      return;
    }
    if (!this.editData.person) {
      this.$message.warning("课题负责人必填！");
      return;
    }
    const fileObj = this.file;
    // FormData 对象
    const form = new FormData();
    // 文件对象
    form.append("_id", this.editData._id);
    form.append("file_name", this.editData.file_name);
    form.append("file_url", this.editData.file_url);
    form.append("name", this.editData.name);
    form.append("person", this.editData.person);
    if (this.file && this.file.name) {
      form.append("file", fileObj);
    }
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    if (this.editData._id) {
      editTopic(this, form, loading)
        .then((res: any) => {
          loading.close();
          this.ifShowAdd = false;
          if (ifUpload) {
            this.$message.success("上传文档成功！");
          } else {
            this.$message.success("编辑成功！");
          }
          this.file = {};
          this.getList();
        })
        .catch(() => {
          loading.close();
        });
    } else {
      addTopic(this, form, loading)
        .then((res: any) => {
          loading.close();
          this.ifShowAdd = false;
          this.$message.success("新增成功！");
          this.file = {};
          this.getList();
        })
        .catch(() => {
          loading.close();
        });
    }
  }
  /**
   * @description 选取文件
   */
  private upload(d: any) {
    this.editData.file_name = "";
    this.file = d.file;
  }
  /**
   * @description 列表上传文档
   */
  private uploadFile(item: any, d: any) {
    this.file = d.file;
    this.editData = JSON.parse(JSON.stringify(item));
    this.addSure("upload");
  }
  private del(item: any) {
    this.$confirm("确定要删除吗？", "删除", {
      customClass: "commonConfirm",
    }).then(() => {
      const params: any = {
        data: {
          _id: item._id,
        },
      };
      delTopic(this, params).then((data: any) => {
        this.$message.success("删除成功");
        this.getList();
      });
    });
  }
  /**
   * @description 获得舌图图片列表
   */
  getList() {
    const params = {
      params: {
        search: this.filters.search,
        current_page: this.filters.current_page,
        page_num: this.filters.page_count,
        order_field: this.filters.sort_field,
        order_value: this.filters.sort_value,
      },
    };
    localStorage.setItem("topicFilter", JSON.stringify(this.filters));
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    getTopic(this, params)
      .then((res: any) => {
        loading.close();
        this.data = res.data;
        this.filters.total_page = res.total_page;
        this.filters.total_count = res.total_count;
        if (this.data.length === 0) {
          this.ifShowEmpty = true;
        } else {
          this.ifShowEmpty = false;
        }
      })
      .catch(() => {
        loading.close();
      });
  }
  private getUser() {
    GetCurrentUserData(this).then((res: any) => {
      this.user = res;
      this.user.role.forEach((ele: any) => {
        if (ele["名称"] !== "研究者") {
          this.ifCanEdit = true;
        }
      });
    });
  }
  /**
   * @description 初始化
   */
  mounted() {
    this.filters.sort_field = "更新时间";
    const d = localStorage.getItem("topicFilter") || "";
    if (d) {
      this.filters = JSON.parse(d);
    }
    this.getList();
    this.getUser();
  }
}
